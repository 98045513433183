'use client';

import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { zodResolver } from "@hookform/resolvers/zod";
import { format, addDays, isSameDay, isValid, isDate } from "date-fns";
import { useTransition, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { usePlace } from '../app/[locale]/PlaceContext';
import { useTranslations } from "next-intl";
import AutocompleteSearchBar from "@/app/[locale]/AutocompleteSearchBar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { useEditBooking } from "@/app/[locale]/EditBookingContext";
import Image from 'next/image';
import LezgoButton from "./LezgoButton";
import { City } from "@/types";
import { useRouter } from "@/i18n/routing"

interface Props {
  city?: City;
  isVariant?: boolean;
}

const BookingFormSchema = z.object({
  location: z.string().min(2, "Location must be at least 2 characters").max(100, "Location must be at most 100 characters"),
  dates: z.object({
    from: z.date({ message: "Invalid date format" }),
    to: z.date({ message: "Invalid date format" })
  })
    .refine(
      (data) => !isSameDay(data.from, data.to),
      {
        message: "Start and end dates cannot be the same day",
      }
    ),
  pickUpTime: z.string().min(1, "Pick-up time is required").max(24, "Invalid time format"),
  returnTime: z.string().min(1, "Return time is required").max(24, "Invalid time format"),
});

export default function BookingForm({ city, isVariant = false }: Props) {
  const t = useTranslations('Home.BookingForm');
  const router = useRouter();
  const { place, setPlace } = usePlace();
  const [isPending, startTransition] = useTransition();
  const [isPlaceValid, setIsPlaceValid] = useState(false);
  const { isEditingBooking, closeEditBooking } = useEditBooking();

  const now = new Date();
  const threeDaysFromNow = addDays(now, 3);
  const sixDaysFromNow = addDays(now, 6);

  const form = useForm<z.infer<typeof BookingFormSchema>>({
    resolver: zodResolver(BookingFormSchema),
    defaultValues: {
      location: "",
      dates: {
        from: threeDaysFromNow,
        to: sixDaysFromNow,
      },
      pickUpTime: "11:00",
      returnTime: "11:00",
    },
  });

  useEffect(() => {
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setIsPlaceValid(!!lat && !!lng);
    } else {
      setIsPlaceValid(false);
    }
  }, [place]);

  useEffect(() => {
    const subscription = form.watch((values) => {
      if (values.location === "") {
        setIsPlaceValid(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  async function onSubmit(values: z.infer<typeof BookingFormSchema>) {
    if (!isPlaceValid) {
      form.setError("location", {
        type: "manual",
        message: "Please select a valid location from the suggestions."
      });
      return;
    }

    const checkin_monthday = values.dates.from.getDate().toString().padStart(2, '0');
    const checkin_month = (values.dates.from.getMonth() + 1).toString().padStart(2, '0');
    const checkin_year = values.dates.from.getFullYear().toString();
    const checkout_monthday = values.dates.to.getDate().toString().padStart(2, '0');
    const checkout_month = (values.dates.to.getMonth() + 1).toString().padStart(2, '0');
    const checkout_year = values.dates.to.getFullYear().toString();

    const pick_up_date = `${checkin_year}-${checkin_month}-${checkin_monthday}`;
    const return_date = `${checkout_year}-${checkout_month}-${checkout_monthday}`;

    const url = new URL("/car-picker", window.location.origin);
    url.searchParams.set("google_maps_address", place?.name || '');
    url.searchParams.set("pick_up_date", pick_up_date);
    url.searchParams.set("return_date", return_date);
    url.searchParams.set("pick_up_time", values.pickUpTime);
    url.searchParams.set("return_time", values.returnTime);
    url.searchParams.set("max_distance", "5");

    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      if (lat && lng) {
        const coordinates = `${lat}%2C${lng}`;
        url.searchParams.set("coordinates", coordinates);
      }
    }

    startTransition(() => {
      router.push(url.href);
      closeEditBooking();
    });
  }

  return (
    <div className={`w-full ${isVariant ? 'z-10 bg-white' : `${isEditingBooking ? 'sm:mt-2' : 'max-w-[600px]'} z-20 sm:mb-4 bg-white border border-slate-100`}`}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
          <div>
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-0 border-b-2 border-slate-100 p-3">
                  <FormLabel className="text-sm tracking-wide text-slate-500 font-semibold">
                    {t('whereTo').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <div className="relative">
                      <AutocompleteSearchBar onChange={(value) => {
                        field.onChange(value);
                        form.clearErrors("location");
                      }} city={city} />
                      <Image
                        src="/images/lezgo/lezgo-blocks.svg"
                        alt="Blocks"
                        width={40}
                        height={40}
                        className={`absolute right-[-20px] bottom-[33px] ${isEditingBooking ? 'sm:right-[-20px] sm:bottom-[-40px]' : "sm:right-[-40px] sm:bottom-[-40px]"} mb-2 mr-2`}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div>
            <FormField
              control={form.control}
              name="dates"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-1 border-b-2 border-slate-100 space-y-0 p-3 pl-2 pb-2">
                  <FormLabel className="text-sm text-slate-500 pl-1 font-semibold">
                    {t('when').toUpperCase()}
                  </FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"ghost"}
                          className={cn(
                            "w-full border-none p-0 pl-1 h-[30px] justify-start text-left font-normal text-slate-500 hover:text-accent-foreground hover:bg-transparent",
                            !field.value.from && "text-muted-foreground"
                          )}
                        >
                          <span className="text-xl sm:text-2xl font-normal">
                            {field.value.from && field.value.to ? (
                              <p>
                                {t('from')} {format(field.value.from, "LLL dd")} - {t('to')} {format(field.value.to, "LLL dd")}
                              </p>
                            ) : field.value.from ? <p> {t('from')} {format(field.value.from, "LLL dd")} - {t('to')} </p> : 
                            <p> {t('from')} - {t('to')} {format(field.value.to, "LLL dd")}
                            </p>}
                          </span>
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="range"
                        selected={field.value}
                        defaultMonth={field.value.from}
                        onSelect={field.onChange}
                        numberOfMonths={2}
                        disabled={(date) =>
                          date <= new Date(new Date().setHours(0, 0, 0, 0))
                        }
                      />
                    </PopoverContent>
                  </Popover>
                  {field.value.from && field.value.to ? <FormMessage /> : (
                    <span className="font-normal text-destructive">{t('selectReturnDate')}</span>
                  )}
                </FormItem>
              )}
            />
          </div>

          <div className={`grid ${isVariant ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-2'}`}>
            <FormField
              control={form.control}
              name="pickUpTime"
              render={({ field }) => (
                <FormItem className="flex space-y-0 flex-col gap-1 border-b-2 border-r-2 border-slate-100 p-3">
                  <FormLabel className="text-sm text-slate-500 font-semibold">
                    {t('pickUpTime').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="time"
                      className="text-xl sm:text-2xl hover:text-accent-foreground hover:cursor-text focus-visible:text-accent px-0 focus-visible:outline-none focus-visible:ring-0 border-none shadow-none font-semibold text-slate-500"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="returnTime"
              render={({ field }) => (
                <FormItem className="flex space-y-0 flex-col gap-1 border-b-2 border-slate-100 p-3">
                  <FormLabel className="text-sm text-slate-500 font-semibold pl-3">
                    {t('returnTime').toUpperCase()}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="time"
                      className="hover:text-accent-foreground hover:cursor-text focus-visible:text-accent focus-visible:outline-none focus-visible:ring-0 border-none shadow-none font-semibold text-slate-500 text-xl sm:text-2xl"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {isVariant && (
              <LezgoButton isPending={isPending} isPlaceValid={isPlaceValid} isVariant={true} />
            )}
          </div>

          {!isVariant && (
            <LezgoButton isPending={isPending} isPlaceValid={isPlaceValid} />
          )}
        </form>
      </Form>
    </div>
  )
}