'use client'

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { Library } from '@googlemaps/js-api-loader';
import { usePlace } from './PlaceContext';
import { useTranslations } from "next-intl";
import { City } from "@/types";

const libs: Library[] = ["core", "maps", "places", "marker"];

interface AutocompleteSearchBarProps {
  onChange: (value: string) => void;
  city?: City;
}

const airports: Record<City, google.maps.places.PlaceResult> = {
  miami: {
    name: "Miami International Airport",
    geometry: {
      location: { lat: () => 25.7959, lng: () => -80.2870 }
    },
    formatted_address: "Miami International Airport"
  } as google.maps.places.PlaceResult,
  curacao: {
    name: "Curacao International Airport",
    geometry: {
      location: { lat: () => 12.1889, lng: () => -68.9598 }
    },
    formatted_address: "Curacao International Airport"
  } as google.maps.places.PlaceResult,
  default: {
    name: "Curacao International Airport",
    geometry: {
      location: { lat: () => 12.1889, lng: () => -68.9598 }
    },
    formatted_address: "Curacao International Airport"
  } as google.maps.places.PlaceResult
};

export default function AutocompleteSearchBar({ onChange, city = 'default' }: AutocompleteSearchBarProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const { setPlace } = usePlace();
  const t = useTranslations('Home.BookingForm');
  const [value, setValue] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
    libraries: libs,
    version: 'beta'
  });

  const getBoundsForCity = useMemo(() => (city: City) => {
    switch (city) {
      case 'miami':
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(25.709, -80.459),
          new google.maps.LatLng(25.909, -80.139)
        );
      case 'curacao':
      default:
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(12.044, -69.11),
          new google.maps.LatLng(12.354, -68.75)
        );
    }
  }, []);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current!.getPlace();
    setPlace(place);
    if (inputRef.current) {
      const selectedValue = inputRef.current.value;
      setValue(selectedValue);
      onChange(selectedValue);
    }
  };

  useEffect(() => {
    if (loadError) {
      setError("Failed to load Google Maps API");
      return;
    }

    if (isLoaded && inputRef.current && !isInitialized) {
      const bounds = getBoundsForCity(city);
      const options: google.maps.places.AutocompleteOptions = {
        bounds,
        componentRestrictions: { country: city === 'miami' ? 'us' : 'cw' },
      };

      autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current!, options);
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);

      const airportInfo = airports[city];
      setPlace(airportInfo);
      setValue(airportInfo.formatted_address || '');
      onChange(airportInfo.formatted_address || '');
      setIsInitialized(true);
    }
  }, [isLoaded, loadError, isInitialized, city, getBoundsForCity, onChange, setPlace]);

  useEffect(() => {
    if (autocompleteRef.current) {
      const bounds = getBoundsForCity(city);
      autocompleteRef.current.setBounds(bounds);
      autocompleteRef.current.setComponentRestrictions({
        country: city === 'miami' ? 'us' : 'cw',
      });
    }
  }, [city, getBoundsForCity]);

  useEffect(() => {
    if (value === "") {
      setPlace(null);
    }
  }, [value, setPlace]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='flex flex-col space-y-4'>
      <input
        ref={inputRef}
        id="autocomplete-input"
        placeholder={t('enterALocation')}
        type="text"
        value={value}
        onChange={handleInputChange}
        disabled={!isLoaded}
        className="flex w-full border-none bg-transparent font-semibold transition-color text-slate-500 placeholder:text-slate-500 !text-xl sm:!text-2xl hover:text-accent-foreground hover:placeholder:text-accent focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
        aria-label={t('enterALocation')}
      />
    </div>
  );
}
